var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "aboutRM home" }, [
    _c("section", { staticClass: "company-section1" }, [
      _c("div", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/circle.png"),
            alt: "이미지"
          }
        }),
        _c("h2", [_vm._v(_vm._s(_vm.$t("company_title_1")))]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("company_context_1_1")) + " "),
          _c("br"),
          _vm._v(_vm._s(_vm.$t("company_context_1_2")) + " "),
          _c("br"),
          _vm._v(_vm._s(_vm.$t("company_context_1_3")) + " "),
          _c("br"),
          _vm._v(_vm._s(_vm.$t("company_context_1_4")) + " ")
        ])
      ]),
      _c("img", {
        attrs: {
          src: require("@/assets/images/reviewmind/product_img.png"),
          alt: "이미지"
        }
      })
    ]),
    _c("section", { staticClass: "company-section2" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/reviewmind/circle.png"),
          alt: "이미지"
        }
      }),
      _c("h2", [_vm._v(_vm._s(_vm.$t("company_title_2")))]),
      _c("div", [
        _c("div", [
          _c("div", [
            _c("h3", [_vm._v(_vm._s(_vm.$t("company_influence_title")))]),
            _vm._m(0)
          ]),
          _c("p", [_vm._v(_vm._s(_vm.$t("company_influence")))])
        ]),
        _c("div", [
          _c("div", [
            _c("h3", [_vm._v(_vm._s(_vm.$t("company_sales_title")))]),
            _vm._m(1)
          ]),
          _c("p", [_vm._v(_vm._s(_vm.$t("company_sales")))])
        ]),
        _c("div", [
          _c("div", [
            _c("h3", [_vm._v(_vm._s(_vm.$t("company_conversion_title")))]),
            _vm._m(2)
          ]),
          _c("p", [_vm._v(_vm._s(_vm.$t("company_conversion")))])
        ])
      ])
    ]),
    _c("section", { staticClass: "company-section3" }, [
      _c("h2", [
        _vm._v(" " + _vm._s(_vm.$t("company_title_3")) + " "),
        _c("br"),
        _vm._v(_vm._s(_vm.$t("company_title_3_1")) + " ")
      ]),
      _c("img", {
        attrs: {
          src: require("@/assets/images/reviewmind/about-section3.png"),
          alt: "이미지"
        }
      })
    ]),
    _vm._m(3),
    _c("div", { staticClass: "mainSection4" }, [
      _c("div", { staticClass: "section4Wrap" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("demo_request")))]),
        _c("button", { on: { click: _vm.move } }, [_vm._v("Watch Demo")])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fas fa-caret-up" }),
      _vm._v(" 10% ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fas fa-caret-up" }),
      _vm._v(" 18% ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fas fa-caret-up" }),
      _vm._v(" 4.6% ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "company-section4" }, [
      _c("div", [
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/about_logo1.png"),
            alt: "로고"
          }
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/about_logo2.png"),
            alt: "로고"
          }
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/about_logo3.png"),
            alt: "로고"
          }
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/about_logo4.png"),
            alt: "로고"
          }
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/about_logo5.png"),
            alt: "로고"
          }
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/about_logo6.png"),
            alt: "로고"
          }
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/about_logo7.png"),
            alt: "로고"
          }
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/about_logo8.png"),
            alt: "로고"
          }
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/about_logo9.png"),
            alt: "로고"
          }
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/about_logo10.png"),
            alt: "로고"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }